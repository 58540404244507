import React, { useState, useEffect } from "react";
import { Container, IndiponibilityChatDiv } from "./styles";
import axios from "axios";
import { scroller } from "react-scroll";

import consts from "../../utils/config";
import { getQueryVariable } from "../../utils/functions";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import Loader from "../../components/Loader";
import Banner from "../../components/Home/Banner";
import AboutUs from "../../components/Home/AboutUs";
import Products from "../../components/Home/Products";
import News from "../../components/Home/News";
import Contact from "../../components/Home/Contact";
import ScrollUpButton from "../../components/Home/ScrollUpButton";
import EmailAlertModal from "../../components/Home/EmailAlertModal";
import HolidayAlert from "../../components/Home/HolidayAlert";

import { BannerType } from "../../@types/home";
import { NewType } from "../../components/Home/News/index";
import { ContactsType, CompanyAddressType } from "../../@types/home";

import texts from "../../utils/texts";

const Home: React.FC = () => {
  const [companyAddress, setCompanyAddress] = useState<CompanyAddressType>({});
  const [contacts, setContacts] = useState<ContactsType>({});
  const [banners, setBanners] = useState<BannerType[]>([]);
  const [news, setNews] = useState<NewType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disponibility, setDisponibility] = useState({
    status: "",
    text: "",
    script: "",
    image: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    document.title = texts.home.browser_title;
  }, []);

  useEffect(() => {
    // Return site data from the API
    axios
      .get(consts.getApiSiteUrl())
      .then((resp) => {
        let site = resp.data.data.site;

        setCompanyAddress({
          address: site.address,
          district: site.district,
          complement: site.complement,
          cep: site.cep,
          city: site.city,
          state: site.state,
        });

        setContacts({
          email: site.email,
          phone: site.phone_number,
        });

        setBanners(site.banners);
        setNews(site.posts);
        setIsLoading(false);
      })
      .catch((resp) => console.log(resp));
  }, []);

  useEffect(() => {
    if (disponibility.status === "online") {
      const scriptString = disponibility?.script;
      const partes = scriptString.match(/"(.*?)"|'(.*?)'/g);

      if (partes) {
        const src = partes[0].replace(/"|'/g, "");
        const token = partes[1].replace(/"|'/g, "");
        const script = document.createElement("script");
        script.src = src;
        script.setAttribute("token", token);
        script.async = true;
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [disponibility]);

  useEffect(() => {
    axios
      .post(consts.getApiChatBotUrl(), { site: 4 })
      .then((resp) => {
        setDisponibility({
          status: "online",
          script: resp.data,
          text: "",
          image: null,
        });
      })
      .catch((error) => {
        setDisponibility({
          status: "offline",
          text: error.response.data.unavailability_message,
          script: "",
          image: error.response.data.image,
        });
      });
  }, []);

  useEffect(() => {
    let to = getQueryVariable("to");
    if (to) {
      scroller.scrollTo(to, {
        duration: 800,
        offset: -100,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [isLoading]);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Container id="home">
        <EmailAlertModal />
        <HolidayAlert />
        <Banner {...banners} />
        <AboutUs />
        <Products />
        <News {...news} />
        <Contact address={companyAddress} contacts={contacts} />
        <ScrollUpButton />
        {disponibility.status === "offline" && disponibility.text != null && (
          <IndiponibilityChatDiv className="schedule_config">
            <Button onClick={handlePopoverOpen}>
              {disponibility.image === null ||
              disponibility.image === undefined ? (
                <img
                  style={{ width: "50px", height: "50px" }}
                  src="/assets/imgs/icon_offline.png"
                  alt=""
                />
              ) : (
                <img
                  style={{ width: "50px", height: "50px" }}
                  src={disponibility.image}
                  alt=""
                />
              )}
            </Button>
            <Popover
              anchorEl={anchorEl}
              open={open}
              id={open ? "simple-popover" : undefined}
              style={{ maxWidth: "40%", maxHeight: "40%" }}
              onClose={handlePopoverClose}
              transformOrigin={{
                horizontal: "left",
                vertical: "bottom",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "center",
              }}
            >
              <div style={{ padding: "10px", whiteSpace: "pre-wrap" }}>{disponibility.text}</div>

            </Popover>
          </IndiponibilityChatDiv>
        )}
      </Container>
    );
  }
};

export default Home;
